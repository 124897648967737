@font-face {
  font-family: 'GraphikLight';
  src: url('Graphik-Light.eot');
  src: local('Graphik Light'), local('Graphik-Light'),
    url('Graphik-Light.eot?#iefix') format('embedded-opentype'),
    url('Graphik-Light.woff') format('woff'),
    url('Graphik-Light.ttf') format('truetype'),
    url('Graphik-Light.svg#Graphik-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GraphikMedium';
  src: url('Graphik-Medium.eot');
  src: local('Graphik-Medium'),
    url('Graphik-Medium.eot?#iefix') format('embedded-opentype'),
    url('Graphik-Medium.woff') format('woff'),
    url('Graphik-Medium.ttf') format('truetype'),
    url('Graphik-Medium.svg#Graphik-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('Graphik-Regular.eot');
  src: local('Graphik-Regular'),
    url('Graphik-Regular.eot?#iefix') format('embedded-opentype'),
    url('Graphik-Regular.woff') format('woff'),
    url('Graphik-Regular.ttf') format('truetype'),
    url('Graphik-Regular.svg#Graphik-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Koor';
  src: url('Koor-RegularNormal.eot');
  src: local('Koor RegularNormal'), local('Koor-RegularNormal'),
      url('Koor-RegularNormal.eot?#iefix') format('embedded-opentype'),
      url('Koor-RegularNormal.woff') format('woff'),
      url('Koor-RegularNormal.svg#SchnyderL-Demi') format('svg');
  font-weight: normal;
  font-style: normal;
}
